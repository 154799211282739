<template>
  <div class="w-full place-content-center">
    <section :class="[styled]">
      <slot />
    </section>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  layout?: '2s' | '2' | '2w' | '3s' | '3' | '3w' | '4'
  noSpacing?: boolean,
  spaceY?: string,
}

const props = withDefaults(defineProps<Props>(), {
  spaceY: 'space-y-5',
  layout: '2',
})

const marginTop = props.noSpacing ? '' : 'mt-11 sm:mt-10'

const layoutMap = {
  '2s': 'columns-1 lg:columns-2 gap-x-10',
  '2': 'columns-1 lg:columns-2 gap-x-32',
  '2w': 'columns-1 lg:columns-2 gap-x-40',
  '3s': 'columns-1 lg:columns-2 xl:columns-3 gap-x-8',
  '3': 'columns-1 lg:columns-2 xl:columns-3 gap-x-20',
  '3w': 'columns-1 lg:columns-2 xl:columns-3 gap-x-40',
  '4': 'columns-1 lg:columns-2 xl:columns-4 gap-x-8',
}

const styled = [
  marginTop,
  layoutMap[props.layout],
  'break-inside-avoid',
  props.spaceY,
].join(' ')
</script>
